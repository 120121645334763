<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- regional -->
            <AFormItem
                label="Pilih Regional"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="regional_id"
                has-feedback>
                <FilterRegional
                    style="width:100%"
                    :disabled="form.readOnly || form.id"
                    :mode="null"
                    v-model:value="form.regional_id"/>
            </AFormItem>

            <!-- provinsi -->
            <AFormItem
                label="Pilih Provinsi"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="provinsi_id"
                has-feedback>
                <FilterProvince
                    style="width:100%"
                    :disabled="form.readOnly || form.id"
                    :mode="item.id ? null : 'multiple'"
                    v-model:region="form.regional_id"
                    v-model:value="form.provinsi_id"/>
            </AFormItem>

            <!-- status -->
            <AFormItem
                label="Status"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="status"
                has-feedback>
                <ARadioGroup
                    v-model:value="form.status"
                    style="width:100%">
                    <a-radio :value="true">Aktif</a-radio>
                    <a-radio :value="false">Non Aktif</a-radio>
                </ARadioGroup>
            </AFormItem>

            <!-- clusters -->
            <AFormItem
                label="Clusters"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required>
                <ASkeleton
                    active
                    :paragraph="{ rows: 3 }"
                    :loading="state.loadingCluster">
                    <section class="ant-form ant-form-vertical">
                        <article
                            v-for="(item, index) in form.clusters"
                            :key="index">
                            <strong>{{ item.cluster_name }}</strong>

                            <ARow
                                :gutter="[8,0]">
                                <ACol :span="12">
                                    <AFormItem
                                        required
                                        :name="['clusters', index, 'min']"
                                        has-feedback>
                                        <AInput
                                            placeholder="Nilai Min"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.min"
                                            :disabled="form.readOnly"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :span="12">
                                    <AFormItem
                                        required
                                        :name="['clusters', index, 'max']"
                                        has-feedback>
                                        <AInput
                                            placeholder="Nilai Max"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.max"
                                            :disabled="form.readOnly"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>
                        </article>
                    </section>
                </ASkeleton>
            </AFormItem>
            <!-- end clusters -->

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            regional_id: [],
            provinsi_id: [],
            status: null,
            clusters: [],
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Konfigurasi Cluster',
            endpoint: {
                create: '/api/cluster/config',
                update: `/api/cluster/config/update/${props.item.id}`,
                show: `/api/cluster/config/show/${props.item.id}`,
                cluster: '/api/filter/cluster',
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loadingCluster: false,
            loading: false,
            rules: {
                regional_id: [
                    {
                        required: true,
                        message: 'Regional tidak boleh kosong!',
                    },
                ],
                provinsi_id: [
                    {
                        required: true,
                        message: 'Provinsi tidak boleh kosong!',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong!',
                    },
                ],
                clusters: [],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint.create)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.post(state.endpoint.update)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        const fetchById = () => {
            state.loadingCluster = true
            apiClient.get(state.endpoint.show)
                .then(({ data }) => {
                    if (data.length) {
                        const item = data[0]

                        form.regional_id = item.regional_id
                        form.provinsi_id = item.provinsi_id
                        form.status = item.status
                        item.clusters.forEach((item) => {
                            form.clusters.push({
                                cluster_id: item.cluster_id,
                                cluster_name: item.cluster,
                                min: item.min,
                                max: item.max,
                            })
    
                            // add validate cluster
                            state.rules.clusters.push({
                                min: [
                                    {
                                        required: true,
                                        message: 'Min tidak boleh kosong!',
                                    },
                                ],
                                max: [
                                    {
                                        required: true,
                                        message: 'Max tidak boleh kosong!',
                                    },
                                ],
                            })
                        })
                    }
                })
                .finally(() => {
                    state.loadingCluster = false
                })
        }

        const fetchCluster = () => {
            state.loadingCluster = true
            apiClient.get(state.endpoint.cluster)
                .then(({ data }) => {
                    data.forEach((item) => {
                        form.clusters.push({
                            cluster_id: item.id,
                            cluster_name: item.name,
                            min: null,
                            max: null,
                        })

                        // add validate cluster
                        state.rules.clusters.push({
                            min: [
                                {
                                    required: true,
                                    message: 'Min tidak boleh kosong!',
                                },
                            ],
                            max: [
                                {
                                    required: true,
                                    message: 'Max tidak boleh kosong!',
                                },
                            ],
                        })
                    })
                })
                .finally(() => {
                    state.loadingCluster = false
                })
        }

        // handle func vue
        onMounted(() => {
            form.reset()

            if (!form.id) {
                fetchCluster()
            } else {
                fetchById()
                
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Konfigurasi Cluster`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
