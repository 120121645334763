<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Cluster"
    style="width: 300px"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
  },
  emits: ['update:value', 'select'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)
    const { value } = toRefs(props)
    const loading = ref(false)

    const fetchData = debounce((q = null) => {
      loading.value = true
      apiClient
        .get('/api/filter/cluster', {
          params: { q },
        })
        .then(response => {
          let sorted = response.data
          
          data.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .catch(err => console.log(err))
        .finally(() => {
          loading.value = false
        })
    }, 500)

    onMounted(() => {
      fetchData()
    })

    watch(value, (after, before) => {
      if (after === null || after === undefined || after === [])
        fetchData()
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchData(value)
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const deselect = value => {}
    const select = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }

      emit('select', value)
    }

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
    }
    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
